import React from 'react';
import styles from './SuccessPage.module.scss';
import StatusLayout from '../../../components/site/StatusLayout/StatusLayout';
const SuccessPage = () => {
  return (
    <>
      <StatusLayout color={'rgb(54, 179, 126)'} title={'Заказ принят!'} text={<p style={{ maxWidth: '405px' }}>В ближайшее время мы свяжемся с вами и отправим вам реквизиты для оплаты</p>} icon={'ri-check-line'} btnColor={'btn-green'} />
    </>
  );
};

export default SuccessPage;
