import React from 'react';
import styles from './PrivacyPage.module.scss';
const PrivacyPage = () => {
  return (
    <>
      <div class="hero_privacy">
        <div class="container">
          <div class="d-flex jusitify-content-center align-items-center space-x-10">
            <h1 class="text-left">
              Пользовательское соглашение
              <i class="ri-file-text-fill privacy__icon " style={{ marginLeft: '10px' }}></i>
            </h1>
          </div>
        </div>
      </div>
      <div class="privacy__page">
        <div class="container">
          <div class="box space-y-30" style={{ padding: '60px 30px' }}>
            <div class="law-text" style={{ fontSize: '19px' }}>
              Настоящая публичная оферта (далее – «Оферта») представляет собой официальное предложение GAMIGOODS, далее именуемый «Магазин», на заключение договора на условиях, описанных в Оферте, адресованного дееспособным физическим лицам, принявшим настоящее предложение. В соответствии с пунктом
              2 статьи 437 ГК РФ документ является публичной Офертой и в случае принятия изложенных ниже условий и оплаты Магазину, лицо, осуществившее Акцепт настоящей Оферты, становится Покупателем. <br />
              <br /> В соответствии с пунктом 3 статьи 438 ГК РФ акцепт Оферты равносилен заключению договора на условиях, изложенных в Оферте, что безоговорочно принимается сторонами.
              <br />
              <br /> <h3>Оплата и возврат </h3>В Магазине вы можете приобрести товары представленные в интернет витрине. Оплата производится на сайте через авторизированный агрегатор платежей различными способами. Для оплаты покупки Вы будете перенаправлены на платежный шлюз для ввода реквизитов.
              Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL. <br />
              <br /> Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается платежной системой и банками партнерами. Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством
              РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем Visa Int. и MasterCard Europe Sprl и МИР. <br />
              <br /> До момента отправки товара вы можете отказаться от товара и получить возврат оплаченных средств. Для этого необходимо написать на email. <br />
              <br />
              <h3> Возврат товара</h3> Обмен и возврат Товара надлежащего качества производится в течение 7 (Семи) дней с момента получения Заказа, не считая дня продажи, если указанный Товар не был в употреблении, сохранены его товарный вид, потребительские свойства, упаковка, пломбы, фабричные
              ярлыки. <br />
              <br /> Для возврата Товара Покупателю необходимо написать нам на email. При возврате Товара передача Товара Магазину в обязательном порядке осуществляется через Почту России и оплачивает сам покупатель. Покупатель не вправе отказаться от Товара надлежащего качества, имеющего
              индивидуально-определенные свойства, если указанный Товар может быть использован исключительно приобретающим его Покупателем. При отказе Покупателя от Товара Магазин возвращает ему фактически уплаченную стоимость Товара, за исключением стоимости доставки, не позднее чем через 10 дней с
              даты получения Магазином требования от Покупателя о возврате денежных средств.
              <br />
              <br /> Обмен и возврат Товара ненадлежащего качества осуществляется в соответствии с законом РФ «О защите прав потребителей».
              <br />
              <br /> <h3>ДОСТАВКА</h3> Доставка осуществляется Почтой России, по стоимости согласно тарифом и входит в общую стоимость заказа. После поступления оплаты формируется посылка и отправляется Покупателю в течение двух дней. Трек номер посылки, по которому можно следить за ее движением на
              сайте почты России, отправляется на адрес электронной почты, указанный в бланке заказа.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPage;
