import { createSlice } from '@reduxjs/toolkit';

import { initStateGetPostCountry, reducerGetPostCountry } from '../actions/post/getPostCountry';
import { initStateFindPostAddresses, reducerFindPostAddresses } from '../actions/post/findPostAddresses';
import { initStateCalculatePostCost, reducerCalculatePostCost } from '../actions/post/calculatePostCost';
import { initStateSendOrder, reducerSendOrder } from '../actions/post/sendOrder';

export const initialState = {
  ...initStateGetPostCountry,
  ...initStateFindPostAddresses,
  ...initStateCalculatePostCost,
  ...initStateSendOrder,
};

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    resetCalculatePostCost(state) {
      state.calculatePostCost = initStateCalculatePostCost.calculatePostCost;
    },
    resetFindPostAddresses(state) {
      state.findPostAddresses = initStateFindPostAddresses.findPostAddresses;
    },
    resetSendOrder(state) {
      state.sendOrder = initStateSendOrder.sendOrder;
    },
  },
  extraReducers: {
    ...reducerGetPostCountry,
    ...reducerFindPostAddresses,
    ...reducerCalculatePostCost,
    ...reducerSendOrder,
  },
});
export const { resetFindPostAddresses, resetCalculatePostCost, resetSendOrder } = postSlice.actions;
export const postReducer = postSlice.reducer;
