export const productList = [
  {
    id: 1,
    name: 'Карточная игра МАФИЯ',
    slug: 'kartochnaya-igra-mafiya',
    prev: '/img/game-1-prev.jpg',
    image: ['/img/m1.jpg', '/img/m2.jpg', '/img/m3.jpg'],
    price: 1200,
    weight: 100,
    desc: (
      <>
        Игровая колода "Мафия" <br />
        Иллюстрации карт созданы по мобильной игре и фандому Identity V. <br /> <br /> Колода состоит из 14 карт: 6 Мирных жителей, 2 Мафии, Дон, Комиссар, Любовница (путана), Доктор, Маньяк и карта Ведущего.
        <br /> Красивые авторские иллюстрации расскажут вам историю исследователя, который в поисках зеркала принцессы Лэчан пребывает в Чайна-Таун, полным страшным тайн и загадок.
      </>
    ),
    specs: (
      <>
        • 14 карт из плотного картона <br />• Глянцевая ламинация, защищающая от стирания <br />• Поверхность не оставляет царапин <br />• Золотое напыление на рубашке карт <br />• Инструкция внутри <br />• Сюжетная история внутри <br />• Размер 70x140 мм
      </>
    ),
    authors: (
      <>
        Идея и разработка: GAMIGOODS <br /> Иллюстрации: Егор Косых
      </>
    ),
    content: (
      <>
        <p>
          Добро пожаловать в мир, где ароматы специй сливаются с шумом улиц, где каждый уголок хранит свою историю, а резные драконы украшают старинные здания. Там узкие улочки переполнены древними артефактами, восточными сокровищами, чайными домиками и уютными лавками чудес. Это удивительное место,
          таинственный квартал Чайна-Таун, где восток встречает Запад под светом красных фонарей. Почувствуйте дыхание старинной культуры и окунитесь в незабываемое путешествие тайн и загадок.
          <br />
          <br />
          Вырезка из путеводителя ресторана «Хрустящая утка» 12 июля 1895 года
        </p>
        <img class="img_article" src={'/img/mafia1.jpg'} />
        <p>
          Колода интеллектуальной игры "Мафия" с персонажами игры Identity V, разработанная и придуманная нами. Колода может использоваться как полноценная игровая, а также как коллекционный набор или в подарок! Такая колода будет украшением любой вечеринки или посиделок с друзьями! А ещё это лучший
          подарок в коллекцию самому себе!
        </p>{' '}
        <img class="img_article" src={'/img/mafia2.jpg'} />
        <p>
          В колоде представлены: 6 мирных жителей: <br />— Лаки Гай, главный герой <br />— Путешественник <br />— Слепая, жена путешественника <br />— Лука Бальза, обслуживает красные фонари в Чайна Тауне <br />— Садовница, официант ресторана <br />— Маленькая девочка, пропала при загадочных
          обстоятельствах <br />— Доктор, владелица клиники
          <br />— Путана, она же Парфюмер, девушка легкого поведения <br />— Маньяк, он же Наемник, был замечен в темных переулках <br />— Комиссар, он же Детектив, расследует дело <br />— 2 Мафии, братья Черно-Белых <br />— Дон, Джек потрошитель, прибыл с братьями в Чайна Таун по темным делам{' '}
          <br />— И карта ведущего, представленная Мисс Найтингейл
        </p>{' '}
        <img class="img_article" src={'/img/mafia3.jpg'} />
        <p>
          Когда-то Чайна-таун был излюбленным местом туристов, но с недавних пор этот район стали обходить стороной. Загадочное исчезновение маленькой девочки повлекло за собой череду покрытых тайнами и слухами событий, что вспыхивают во тьме, словно мерцающие красные огни фонарей, и оставляют после
          себя лишь запустение и одинокие блуждающие силуэты. Погрузитесь в мрачную историю и разгадайте все её секреты.
        </p>{' '}
        <img class="img_article" src={'/img/mafia4.jpg'} />
        <p>Наша колода Мафии не только развлечет вас и ваших друзей, но и украсит любую вечеринку!</p> <img class="img_article" src={'/img/mafia5.jpg'} />
      </>
    ),
  },
  {
    id: 2,
    name: 'Календарь 2024 – Identity V',
    slug: 'kalendar-2024-identity-v',
    prev: '/img/game-2-prev.jpg',
    image: ['/img/c1.jpg', '/img/c2.jpg', '/img/c3.jpg'],
    price: 1500,
    weight: 222,
    desc: (
      <>
        Календарь на 2024 год "Зов Бездны. Альтернатива". Создам по мотивам игры Identity V. <br /> 12 листов с красивыми иллюстрациями и сотнями мелких деталей. <br /> Проект является иллюстрированным комиксом, в котором вы узнаете, как в деревню Выживших пришла Бездна, и с какими последствиями
        столкнулись наши герои.
      </>
    ),
    specs: (
      <>
        • 12 листов с иллюстрациями <br />• Глянцевая поверхность листов <br />• Все персонажи Identity V <br />• Полная сюжетная история <br />• Формат А3 <br />
      </>
    ),
    authors: (
      <>
        Идея и разработка: GAMIGOODS <br /> Иллюстрации: Алиса Кроберт
      </>
    ),
    content: (
      <>
        <p>Каждый месяц погода и окружение локации будет меняться, а персонажи Identity V будут участвовать в различных ситуациях и событиях. Тут вам и: шуточные отсылки, неожиданные сюжетные повороты, праздничное торжество, раскрытие главных персонажей и многое другое.</p>
        <img class="img_article" src={'/img/1.jpg'} />
        <p>Узнай полную историю, как Офицер выловил из озера кристалл Бездны, принес его в деревню, и что случилось после.</p>
        <img class="img_article" src={'/img/2.jpg'} />
        <p>12 красочных иллюстраций на каждый месяц, сотни отрисованных объектов, абсолютно все персонажи Identity V задействованы в историях.</p> <img class="img_article" src={'/img/3.jpg'} />
      </>
    ),
  },
];
export const socialData = [
  {
    icon: (
      <svg viewBox="0 0 64 64" width="32" height="32">
        <circle cx="32" cy="32" r="32" fill="#4C75A3"></circle>
        <path
          d="M44.94,44.84h-0.2c-2.17-.36-3.66-1.92-4.92-3.37C39.1,40.66,38,38.81,36.7,39c-1.85.3-.93,3.52-1.71,4.9-0.62,1.11-3.29.91-5.12,0.71-5.79-.62-8.75-3.77-11.35-7.14A64.13,64.13,0,0,1,11.6,26a10.59,10.59,0,0,1-1.51-4.49C11,20.7,12.56,21,14.11,21c1.31,0,3.36-.29,4.32.2C19,21.46,19.57,23,20,24a37.18,37.18,0,0,0,3.31,5.82c0.56,0.81,1.41,2.35,2.41,2.14s1.06-2.63,1.1-4.18c0-1.77,0-4-.5-4.9S25,22,24.15,21.47c0.73-1.49,2.72-1.63,5.12-1.63,2,0,4.84-.23,5.62,1.12s0.25,3.85.2,5.71c-0.06,2.09-.41,4.25,1,5.21,1.09-.12,1.68-1.2,2.31-2A28,28,0,0,0,41.72,24c0.44-1,.91-2.65,1.71-3,1.21-.47,3.15-0.1,4.92-0.1,1.46,0,4.05-.41,4.52.61,0.39,0.85-.75,3-1.1,3.57a61.88,61.88,0,0,1-4.12,5.61c-0.58.78-1.78,2-1.71,3.27,0.05,0.94,1,1.67,1.71,2.35a33.12,33.12,0,0,1,3.92,4.18c0.47,0.62,1.5,2,1.4,2.76C52.66,45.81,46.88,44.24,44.94,44.84Z"
          fill="white"></path>
      </svg>
    ),
    name: (
      <div>
        {' '}
        Вконтакте <b style={{ color: '#ff9909' }}>gamigoods</b>
      </div>
    ),
    link: 'https://vk.com/gamigoods',
  },
  {
    icon: (
      <svg fill="none" height="32" width="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
        <path d="M250 500c138.071 0 250-111.929 250-250S388.071 0 250 0 0 111.929 0 250s111.929 250 250 250z" fill="#34aadf" />
        <path
          d="M104.047 247.832s125-51.3 168.352-69.364c16.619-7.225 72.977-30.347 72.977-30.347s26.012-10.115 23.844 14.451c-.723 10.116-6.503 45.52-12.283 83.815-8.671 54.191-18.064 113.439-18.064 113.439s-1.445 16.619-13.728 19.509-32.515-10.115-36.127-13.006c-2.891-2.167-54.191-34.682-72.977-50.578-5.058-4.335-10.838-13.005.722-23.121 26.012-23.844 57.081-53.468 75.867-72.254 8.671-8.671 17.341-28.902-18.786-4.336-51.3 35.405-101.878 68.642-101.878 68.642s-11.561 7.225-33.237.722c-21.677-6.502-46.966-15.173-46.966-15.173s-17.34-10.838 12.284-22.399z"
          fill="#fff"
        />
      </svg>
    ),
    name: (
      <div>
        {' '}
        Telegram <b style={{ color: '#ff9909' }}>@gamigoods</b>
      </div>
    ),
    link: 'https://t.me/gamigoods',
  },
  {
    icon: <img style={{ width: '32px', height: '32px', borderRadius: '50%' }} src={'/img/tiktok.png'} />,
    name: (
      <div>
        {' '}
        TikTok <b style={{ color: '#ff9909' }}>@gamigoods</b>
      </div>
    ),
    link: 'https://www.tiktok.com/@gamigoods',
  },
  {
    icon: <img style={{ width: '32px', height: '32px', borderRadius: '50%', transform: 'scale(1.3)' }} src={'/img/gmail-2.svg'} />,
    name: (
      <div>
        {' '}
        Gmail <b style={{ color: '#ff9909' }}>gamigoods.ru@gmail.com</b>
      </div>
    ),
    link: 'mailto:gamigoods.ru@gmail.com',
  },
  // {
  //   icon: <img style={{ width: '32px', height: '32px', borderRadius: '50%', objectFit: 'contain' }} src={'/img/youtube.svg'} />,
  //   name: (
  //     <div>
  //       {' '}
  //       YouTube <b style={{ color: '#ff9909' }}>@mafia_youtube</b>
  //     </div>
  //   ),
  //   link: 'https://www.youtube.com/@vladkozyra',
  // },
  // {
  //   icon: <img style={{ width: '32px', height: '32px', borderRadius: '50%', objectFit: 'contain' }} src={'/img/instagram.svg'} />,
  //   name: (
  //     <div>
  //       {' '}
  //       Instagram <b style={{ color: '#ff9909' }}>@mafia_instagram</b>
  //     </div>
  //   ),
  //   link: 'https://www.youtube.com/@vladkozyra',
  // },
  // {
  //   icon: (
  //     <svg viewBox="0 0 64 64" width="32" height="32">
  //       <circle cx="32" cy="32" r="32" fill="#000000"></circle>
  //       <path d="M 41.116 18.375 h 4.962 l -10.8405 12.39 l 12.753 16.86 H 38.005 l -7.821 -10.2255 L 21.235 47.625 H 16.27 l 11.595 -13.2525 L 15.631 18.375 H 25.87 l 7.0695 9.3465 z m -1.7415 26.28 h 2.7495 L 24.376 21.189 H 21.4255 z" fill="white"></path>
  //     </svg>
  //   ),
  //   name: (
  //     <div>
  //       {' '}
  //       X <b style={{ color: '#ff9909' }}>@mafia_x</b>
  //     </div>
  //   ),
  //   link: 'https://www.youtube.com/@vladkozyra',
  // },
];
export const questionData = [
  {
    question: 'What is an NFT?',
    answer: ` NFTs or non-fungible tokens, are cryptographic assets on blockchain with unique identification codes and metadata that distinguish them from each other. NFTs are unique and not mutually interchangeable, which means no two NFTs are the same. NFTs can be a unique digital artwork,
                  sneaker in a limited-run fashion line, in-game item, digital collectible etc.`,
  },
  {
    question: 'What is an NFT?',
    answer: ` NFTs or non-fungible tokens, are cryptographic assets on blockchain with unique identification codes and metadata that distinguish them from each other. NFTs are unique and not mutually interchangeable, which means no two NFTs are the same. NFTs can be a unique digital artwork,
                  sneaker in a limited-run fashion line, in-game item, digital collectible etc.`,
  },
];
